<template>
  <b-modal
    v-model="modalUp"
    centered
    title="Create Tolerance Time Rule"
    ok-title="Send Request"
    size="xsm"
    @hidden="hiddenModal()"
  >
    <validation-observer
      ref="tolerancetime"
    >
      <b-row>
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="rule_type"
            rules="required"
          >
            <b-form-group label="Rule type">
              <b-select
                v-model="ruleOption"
                :class="{ 'border-danger': errors[0] }"
                :options="rulesOptions"
                value-field="id"
                text-field="value"
                
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="minutes_amount"
              :rules="{
                required: true,
                min: 1,
                min_value: 1,
                max: 99,
                max_value: 99,
              }"
            >
              <b-form-group label="Time Limit (Minutes)">
                <b-form-input
                  v-model="minutes"
                  :class="{ 'border-danger': errors[0] }"
                  inputmode="numeric"
                  min="0"
                  max="99"
                  :style="{ 'text-align': 'center' }"
                  type="number"
                  @keydown.up.prevent="incrementMinutes"
                  @keydown.down.prevent="decrementMinutes"
                />
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-col>
      </b-row>
      <b-row v-if="!createMode">
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="rule_type"
            rules="required"
          >
            <b-form-group label="Status">
              <b-select
                v-model="statusOption"
                :class="{ 'border-danger': errors[0] }"
                :options="statusOptions"
                value-field="id"
                text-field="value"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <b-button
        variant="primary"
        :disaabled="isLoading"
        @click="
          createMode ? createToleranceTimeRule() : editToleranceTimeRule()
        "
      >{{ createMode ? "CREATE RULE" : "EDIT RULE" }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import AttendanceServices from '@/views/management/views/settings/services/settings.services';

export default {
  props: {
    createMode: {
      type: Boolean,
      default: true,
    },
    timeRuleData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    isLoading: false,
    modalUp: true,
    minutes: 0,
    observation: null,
    ruleId: null,
    ruleOption: null,
    rulesOptions: [
      { id: null, value: 'Select a rule type',disabled: true },
      { id: 1, value: 'Attendance' },

    ],
    statusOption: null,
    statusOptions: [
      { id: 'active', value: 'Active' },
      { id: 'inactive', value: 'Inactive' },
    ],
    status: null,
  }),
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    currentUserId() {
      return this.currentUser.user_id;
    },
  },
  created() {
    if (!this.createMode) {
      this.minutes = this.timeRuleData.minutes;
      this.ruleOption = this.timeRuleData.type === 'attendance' ? 1 : 2;
      this.statusOption = this.timeRuleData.status;
    }
    this.ruleOption = 1;
  },
  methods: {
    async createToleranceTimeRule() {
      const isValidated = await this.$refs.tolerancetime.validate();
      if (!isValidated) return;
      const swalConfirm = await this.showConfirmSwal();
      if (!swalConfirm.isConfirmed) return;

      const params = {
        minutes: this.minutes,
        type: this.ruleOption,
        user_id: this.currentUserId,
      };

      try {
        this.isLoading = true;
        this.addPreloader();
        const {
          data: { already_exist_this_tolerance_rule },
          status,
        } = await AttendanceServices.createToleranceTimeRule(params);
        // eslint-disable-next-line camelcase
        if (already_exist_this_tolerance_rule) {
          this.showInfoSwal('Already exists a rule with this type');
          return;
        }
        if (status === 200) {
          this.hiddenModal(true);
          this.showSuccessSwal();
        }
      } catch (err) {
        console.error(err);
        this.hiddenModal();
      } finally {
        this.isLoading = false;
        this.removePreloader();
      }
    },
    async editToleranceTimeRule() {
      const swalConfirm = await this.showConfirmSwal();
      if (!swalConfirm.isConfirmed) return;

      const params = {
        tolerance_time_id: this.timeRuleData.id,
        minutes: this.minutes,
        type: this.ruleOption,
        status: this.statusOption,
        observation: this.observation,
        user_id: this.currentUserId,
      };

      try {
        this.isLoading = true;
        this.addPreloader();
        const {
          data: { already_exist_this_tolerance_rule },
          status,
        } = await AttendanceServices.editToleranceTimeRule(params);
        // eslint-disable-next-line camelcase
        if (already_exist_this_tolerance_rule) {
          this.showInfoSwal('Already exists a rule with this type');
          return;
        }

        if (status === 200) {
          this.hiddenModal(true);
          this.showSuccessSwal();
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.removePreloader();
        this.isLoading = false;
      }
      this.isLoading = false;
      this.removePreloader();
    },
    incrementMinutes() {
      this.minutes += 1;
    },
    decrementMinutes() {
      if (this.minutes < 1) return;
      this.minutes -= 1;
    },
    hiddenModal(success = false) {
      this.$emit('closeModal', success);
      this.modalUp = false;
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <div>
    <b-modal
      title="TRACKING"
      size="lg"
      modal
      centered
      title-class="h4 text-white font-weight-bolder"
      header-class="class_modal_js"
      hide-footer
      v-model="modalUp"
      scrollable
      @hidden="hiddenModal"
    >
      <div class="table-responsive">
        <b-table
          ref="tracking-table"
          small
          :items="myProvider"
          :fields="fields"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(type)="data">
            <span>{{ data.item.type | myFontCapitalize }} </span>
          </template>
          <template #cell(status)="data">
            <span
              :class="[
                data.item.status == 'active' ? 'text-success' : 'text-danger',
              ]"
              >{{ data.item.status | myFontCapitalize }}
            </span>
          </template>
          <template #cell(minutes)="data">
            <span
              :style="{
                letterSpacing: '1px',
                fontSize: '12px',
                fontWeight: 'bold',
                display: 'inline-block',
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                backgroundColor: '#0080FF',
                color: '#FFFFFF',
                textAlign: 'center',
                lineHeight: '25px',
                boxShadow: '1px 1px 5px 2px rgba(0, 0, 0, 0.2)',
              }"
              >{{ data.item.minutes }}
            </span>
          </template>
          <template #cell(created_by)="data">
            {{ data.item.created_by }} <br />
            {{ data.item.created_at | myGlobalWithHour }}
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>
  
<script>
import AttendaceServices from "@/views/management/views/settings/services/settings.services.js";
import Fields from "@/views/management/views/settings/data/settings_tracking.fields.js";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: Fields,
      modalUp: true,
    };
  },
  methods: {
    async myProvider() {
      try {
        this.addPreloader();
        const {
          data: { data },
        } = await AttendaceServices.getToleranceTimeTracking({
          tolerance_time_id: this.id,
        });
        this.removePreloader();
        return data || [];
      } catch (err) {
        this.removePreloader();
        console.error(err);
      }
    },
    hiddenModal(success = false) {
      this.$emit("closeModal", success);
    },
  },
  watch: {},
};
</script>
  
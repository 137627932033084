<template>
  <div>
        <b-row class="justify-content-end mr-1 my-1">
          <b-badge 
          class=""
          v-if="hasAvaialableToleranceTimeType" 
          :variant='hasAvaialableToleranceTimeType?"danger":""'>
          {{ hasAvaialableToleranceTimeType ? 'You have active rules,please edit or delete' : '' }}
        </b-badge>  
          <b-button
            variant="success"
            class="ml-1"
            @click="openCreateToleranceTimeModal"
            :disabled="hasAvaialableToleranceTimeType"
          >
          <feather-icon
            icon="PlusIcon"
            size="15"
            class="mr-50 text-white"
            
          />CREATE
          </b-button>
        </b-row>
  
        <b-table
          ref="settingsManagement"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="myProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(type)="data">
            <span>{{ data.item.type | myFontCapitalize }} </span>
          </template>
          <template #cell(status)="data">
            <span
              :class="[
                data.item.status == 'active' ? 'text-success' : 'text-danger',
              ]"
                >{{ data.item.status | myFontCapitalize }}
              </span>
          </template>
          <template #cell(minutes)="data">
            <span
              :style="{
                letterSpacing: '1px',
                fontSize: '12px',
                fontWeight: 'bold',
                display: 'inline-block',
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                backgroundColor: '#0080FF',
                color: '#FFFFFF',
                textAlign: 'center',
                lineHeight: '25px',
                boxShadow: '1px 1px 5px 2px rgba(0, 0, 0, 0.2)',
              }"
              >{{ data.item.minutes }}
            </span>
          </template>
          <template #cell(created_by)="data">
            <b-container>
              <b-row>{{ data.item.created_by }}</b-row>
              <b-row>{{ data.item.created_at | myGlobalWithHour }}</b-row>
            </b-container>
          </template>
          <template #cell(actions)="data">
            <feather-icon
              icon="EditIcon"
              size="14"
              class="text-warning cursor-pointer ml-1 mr-1"
              @click="editToleranceTimeRule(data.item)"
            />
            <feather-icon
              icon="TrashIcon"
              size="14"
              class="text-danger cursor-pointer mr-1"
              @click="deleteToleranceTimeRule(data.item.id)"
            />
          </template>
          <template #cell(tracking)="data">
            <feather-icon
              icon="ListIcon"
              size="15"
              class="mr-50 cursor-pointer"
              v-b-tooltip.hover.top="'Tracking'"
              @click="openToleranceTrackingModal(data.item.id)"
            />
          </template>
        </b-table>


    <create-tolerance-time-modal
      v-if="createToleranceTimeModalOpened"
      :createMode="createModeToleranceTimeModal"
      :timeRuleData="currentTimeRuleData"
      @closeModal="closeCreateToleranceTimeModal"
    />
    <tolerance-tracking-modal
      v-if="toleranceTrackingModalOpened"
      :id="currentToleranceTimeId"
      @closeModal="closeToleranceTrackingModal"
    />
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import fieldsData from "@/views/management/views/settings/data/settings.fields.js";
import CreateToleranceTimeModal from "@/views/management/views/settings/modals/CreateToleranceTimeModal.vue";
import AttendanceServices from "@/views/management/views/settings/services/settings.services.js";
import ToleranceTrackingModal from "@/views/management/views/settings/modals/TolereranceTrackingModal.vue";

export default {
  components: {
    CreateToleranceTimeModal,
    ToleranceTrackingModal,
  },

  data() {
    return {
      currentTimeRuleData: {},
      createModeToleranceTimeModal: true,
      createToleranceTimeModalOpened: false,
      fields: fieldsData,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Tolerance time type...",
        model: "",
      },
      nextPage: "",
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      toleranceData:0,
      startPage: 0,
      toPage: 0,
      toleranceTrackingModalOpened: false,
      isBusy: false,
      sortBy: "name",
      sortDesc: true,
      rulesOptions: [
      { id: 1, value: 'Attendance' },
    ],
    hasAvaialableToleranceTimeType: false,
    };
  },
  methods: {
    openToleranceTrackingModal(tolerance_time_id) {
      this.currentToleranceTimeId = tolerance_time_id;
      this.toleranceTrackingModalOpened = true;
    },
    closeToleranceTrackingModal() {
      this.currentToleranceTimeId = null;
      this.toleranceTrackingModalOpened = false;
    },
    openCreateToleranceTimeModal() {
      this.createModeToleranceTimeModal = true;
      this.createToleranceTimeModalOpened = true;
    },
    closeCreateToleranceTimeModal(success) {
      success && this.$refs.settingsManagement.refresh();
      this.createToleranceTimeModalOpened = false;
    },
    editToleranceTimeRule(item) {
      this.currentTimeRuleData = item;
      this.createModeToleranceTimeModal = false;
      this.createToleranceTimeModalOpened = true;
    },
    async myProvider(ctx) {
      const params = {
        page: ctx.currentPage,
        perPage: ctx.perPage,
      };
      try {
        const data = await AttendanceServices.getToleranceTimeRules(params);
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.totalRows = data.data.total;
        this.startPage = data.data.from;
        this.nextPage = this.startPage + 1;
        this.toPage = data.data.to;
        this.toleranceData = data.data.data;
        this.existTypeToleranceTime();
        return data.data.data || [];
      } catch (err) {
        console.error("Failed to get tolerance time rules", err);
      }
    },
    async deleteToleranceTimeRule(tolerance_time_id) {
      const swalConfirm = await this.showConfirmSwal();
      if (!swalConfirm.isConfirmed) return;
      try {
        this.addPreloader();
        const { status } = await AttendanceServices.deleteToleranceTimeRule({
          tolerance_time_id,
        });
        if (status === 200) {
          this.showSuccessSwal();
          this.$refs.settingsManagement.refresh();
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.removePreloader();
      }
    },existTypeToleranceTime(){
      let existingTypes = this.toleranceData.map(item => item.type);
      existingTypes = existingTypes.map(item => item.charAt(0).toUpperCase() + item.slice(1));
      const hasMissingValue = this.rulesOptions.filter(option => !existingTypes.includes(option.value));
      this.hasAvaialableToleranceTimeType=hasMissingValue.length>0?false:true;
  
  },
},
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    
  },
};
</script>
  
<style scoped>
</style>
  
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","title":"Create Tolerance Time Rule","ok-title":"Send Request","size":"xsm"},on:{"hidden":function($event){return _vm.hiddenModal()}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","disaabled":_vm.isLoading},on:{"click":function($event){_vm.createMode ? _vm.createToleranceTimeRule() : _vm.editToleranceTimeRule()}}},[_vm._v(_vm._s(_vm.createMode ? "CREATE RULE" : "EDIT RULE"))])]},proxy:true}]),model:{value:(_vm.modalUp),callback:function ($$v) {_vm.modalUp=$$v},expression:"modalUp"}},[_c('validation-observer',{ref:"tolerancetime"},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"rule_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Rule type"}},[_c('b-select',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.rulesOptions,"value-field":"id","text-field":"value"},model:{value:(_vm.ruleOption),callback:function ($$v) {_vm.ruleOption=$$v},expression:"ruleOption"}})],1)]}}])})],1),_c('b-col',[_c('b-col',[_c('validation-provider',{attrs:{"name":"minutes_amount","rules":{
              required: true,
              min: 1,
              min_value: 1,
              max: 99,
              max_value: 99,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Time Limit (Minutes)"}},[_c('b-form-input',{class:{ 'border-danger': errors[0] },style:({ 'text-align': 'center' }),attrs:{"inputmode":"numeric","min":"0","max":"99","type":"number"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.incrementMinutes.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.decrementMinutes.apply(null, arguments)}]},model:{value:(_vm.minutes),callback:function ($$v) {_vm.minutes=$$v},expression:"minutes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),(!_vm.createMode)?_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"rule_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status"}},[_c('b-select',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.statusOptions,"value-field":"id","text-field":"value"},model:{value:(_vm.statusOption),callback:function ($$v) {_vm.statusOption=$$v},expression:"statusOption"}})],1)]}}],null,false,346423370)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
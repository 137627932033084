var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":"TRACKING","size":"lg","modal":"","centered":"","title-class":"h4 text-white font-weight-bolder","header-class":"class_modal_js","hide-footer":"","scrollable":""},on:{"hidden":_vm.hiddenModal},model:{value:(_vm.modalUp),callback:function ($$v) {_vm.modalUp=$$v},expression:"modalUp"}},[_c('div',{staticClass:"table-responsive"},[_c('b-table',{ref:"tracking-table",attrs:{"small":"","items":_vm.myProvider,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(type)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("myFontCapitalize")(data.item.type))+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('span',{class:[
              data.item.status == 'active' ? 'text-success' : 'text-danger' ]},[_vm._v(_vm._s(_vm._f("myFontCapitalize")(data.item.status))+" ")])]}},{key:"cell(minutes)",fn:function(data){return [_c('span',{style:({
              letterSpacing: '1px',
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'inline-block',
              width: '25px',
              height: '25px',
              borderRadius: '50%',
              backgroundColor: '#0080FF',
              color: '#FFFFFF',
              textAlign: 'center',
              lineHeight: '25px',
              boxShadow: '1px 1px 5px 2px rgba(0, 0, 0, 0.2)',
            })},[_vm._v(_vm._s(data.item.minutes)+" ")])]}},{key:"cell(created_by)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.created_by)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(data.item.created_at))+" ")]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
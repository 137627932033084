export default [
    {
        key: 'type',
        label: 'Type',
        class: 'text-center pt-1',
    },
    {
        key: 'minutes',
        label: 'Minutes',
        class: 'text-center pt-1',
    },
    {
        key: 'status',
        label: 'Status',
        class: 'text-center pt-1'
    },
    {
        key: 'created_by',
        label: 'Created By',
        class: 'text-center'
    }
]
export default [
    {
        key: 'type',
        label: 'Type',
        class: 'text-center',
    },
    {
        key: 'minutes',
        label: 'Minutes',
        class: 'text-center',
    },
    {
        key: 'status',
        label: 'Status',
    },
    {
        key: 'created_by',
        label: 'Created By',
    },
    {
        key: 'actions',
        label: 'Actions',
    },
    {
        key: 'tracking',
        label: 'Tracking',
        class: 'text-center',
    }
]
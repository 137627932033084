import { amgApi } from "@/service/axios";

class SettingsServices {

    async getToleranceTimeRules(params) {
        try {
            const data = await amgApi.post("/attendance/get-tolerance-time-rules", params);
            return data;
        } catch (err) {
            console.error(err);
        }
    }

    async createToleranceTimeRule(params) {
        try {
            console.log(params);
            const data = await amgApi.post("/attendance/create-tolerance-time", params);
            return data;
        } catch (err) {
            console.error(err);
        }
    }

    async editToleranceTimeRule(params) {
        try {
            console.log(params);
            const data = await amgApi.post("/attendance/edit-tolerance-time", params);
            return data;
        } catch (err) {
            console.error(err);
        }
    }

    async deleteToleranceTimeRule(params) {
        try {
            console.log(params);
            const data = await amgApi.post("/attendance/delete-tolerance-time-rule", params);
            return data;
        } catch (err) {
            console.error(err);
        }
    }

    async getToleranceTimeTracking(params) {
        try {
            console.log(params);
            const data = await amgApi.post("/attendance/get-tolerance-time-tracking", params);
            return data;
        } catch (err) {
            console.error(err);
        }
    }
}
export default new SettingsServices();
